import axios, { GenericAbortSignal } from 'axios';
import { auth } from 'lib/speechify';
import { INTEGRATION_FILES_SETTINGS_KEY, ROOT_FOLDER_ID, SHARED_FOLDER_ID } from './constants';
import {
  IntegrationItemRecord,
  IntegrationAuthorizations,
  IntegrationAuthorizeResponse,
  IntegrationFilesResponse,
  IntegrationService,
  SORT_BY_FIELD,
  SORT_ORDER,
  IntegrationFileType,
  IntegrationFile
} from 'interfaces/integrations';
import { getCustomAccountSetting } from 'utils/baseAccountSettings';

export async function fetchIntegrations<T>(path: string, options?: { signal?: GenericAbortSignal }): Promise<T>;
export async function fetchIntegrations(path: string, options: { blob: true; signal?: GenericAbortSignal }): Promise<Blob>;
export async function fetchIntegrations<T>(path: string, { blob, signal }: { blob?: boolean; signal?: GenericAbortSignal } = { blob: false }): Promise<T> {
  const idToken = await auth.currentUser?.getIdToken();
  const url = new URL(path, process.env.NEXT_PUBLIC_INTEGRATIONS_BASEURL).toString();

  return axios
    .get<T>(url, {
      responseType: blob ? 'blob' : 'json',
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      signal
    })
    .then(response => {
      return response.data;
    });
}

export function getAuths() {
  return fetchIntegrations<IntegrationAuthorizations>('/services/authorized');
}

export function authorize(service: IntegrationService, options?: Record<string, string>) {
  const queryParams = new URLSearchParams({
    ...options,
    redirectUri: `${window.location.origin}/auth-handler?service=${service}`
  });

  return fetchIntegrations<IntegrationAuthorizeResponse>(`/services/${service}/authorize?${queryParams.toString()}`);
}

export async function fetchFiles(
  service: IntegrationService,
  {
    folder,
    cursor,
    sortBy,
    sortOrder,
    filter,
    search,
    signal
  }: {
    folder?: string | null;
    cursor?: string | null;
    sortBy?: SORT_BY_FIELD;
    sortOrder?: SORT_ORDER;
    filter?: string;
    search?: string;
    signal?: GenericAbortSignal;
  } = {}
): Promise<IntegrationFilesResponse> {
  const queryParams = new URLSearchParams({
    pageSize: '100'
  });

  if (sortBy && sortOrder) {
    queryParams.append('sorting', `${sortBy}:${sortOrder}`);
  }

  const filterQueries: string[] = [];

  if (search) {
    filterQueries.push(`name contains '${search}'`);
  } else {
    if (folder) {
      if (folder === SHARED_FOLDER_ID) {
        filterQueries.push('sharedWithMe');
      } else if (folder !== ROOT_FOLDER_ID || service === IntegrationService.GOOGLE_DRIVE) {
        queryParams.append('directory', folder);
      }
    }

    if (filter) {
      const mimeTypes = filter.split('|');
      filterQueries.push(`(${mimeTypes.map(mimeType => `mimeType='${mimeType}'`).join(' or ')})`);
    }
  }

  if (cursor) {
    queryParams.append('cursor', cursor);
  }

  if (filterQueries.length) {
    queryParams.append('query', filterQueries.join(' AND '));
  }

  const { files, cursor: nextCursor } = await fetchIntegrations<IntegrationFilesResponse>(`/services/${service}/files?${queryParams}`, { signal });

  return {
    files: files.map(item => {
      if (item.type === IntegrationFileType.FOLDER && service === IntegrationService.DROPBOX) {
        // Dropbox returns createdTime: Date.now() for folders
        // it leads to incorrect sorting and value displayed
        item.createdTime = null;
      }

      if (folder === SHARED_FOLDER_ID && item.directory === item.id) {
        return { ...item, directory: SHARED_FOLDER_ID };
      } else if (folder === ROOT_FOLDER_ID) {
        return { ...item, directory: ROOT_FOLDER_ID, isShared: false };
      } else if (folder) {
        return { ...item, directory: folder };
      }
      return item;
    }),
    cursor: nextCursor
  };
}

export function fetchFile(service: IntegrationService, fileId: string) {
  return fetchIntegrations<IntegrationItemRecord>(`/services/${service}/files/${fileId}`);
}

export function downloadFile(url: string) {
  return fetchIntegrations(url, { blob: true });
}

export function logout(service: IntegrationService) {
  return fetchIntegrations<{ logout: boolean }>(`/services/${service}/logout`);
}

export async function fetchSuggestedItems(services: IntegrationService[], maxCount: number = 1): Promise<IntegrationFile[]> {
  if (!services.length) return [];

  const getImportedIds = async (service: IntegrationService): Promise<[IntegrationService, string[]]> => {
    try {
      const idsResponse = await getCustomAccountSetting(INTEGRATION_FILES_SETTINGS_KEY[service]);
      return [service, JSON.parse(idsResponse as string).ids as string[]];
    } catch {
      return [service, []];
    }
  };

  const excludeFilesQuery = await Promise.all(services.map(getImportedIds)).catch(() => []);
  const query = new URLSearchParams({
    query: `createdTime > '${new Date(Date.now() - 2 * 24 * 60 * 60 * 1000).toISOString()}'`,
    maxResults: String(maxCount),
    excludeFiles: JSON.stringify(Object.fromEntries(excludeFilesQuery))
  });
  services.forEach(service => query.append('includeServices', service));

  return fetchIntegrations<{ files: IntegrationFile[] }>(`/services/search-all?${query}`).then(({ files }) => files);
}
