import { logError, measure } from 'lib/observability';
import { createNonPersistentStore } from 'lib/zustand';
import { ErrorWithContext } from 'utils/error';

import { MeasurementContextType, MeasurementKey, MeasurementTypes } from './measurementTypes';

export type ProfilingStoreState = {
  lastNavigationStartTimestamp: number;
} & Partial<MeasurementTypes>;

export const useProfilingStore = createNonPersistentStore<ProfilingStoreState>(() => ({
  lastNavigationStartTimestamp: performance.now()
}));

const startMeasurement = <Key extends MeasurementKey>(measurementKey: Key, context?: MeasurementContextType<Key>) => {
  useProfilingStore.setState(state => ({
    ...state,
    [measurementKey]: {
      ...(context ?? {}),
      startTimestamp: performance.now()
    }
  }));
};

const endMeasurement = <Key extends MeasurementKey>(measurementKey: Key, contextParam?: MeasurementContextType<Key>) => {
  const state = useProfilingStore.getState();
  const measurementContent = state[measurementKey];
  if (!measurementContent) {
    return;
  }
  const { startTimestamp, ...context } = measurementContent;
  const endTimestamp = performance.now();
  measure(measurementKey, endTimestamp - startTimestamp, {
    context: {
      ...(context ?? {}),
      ...(contextParam ?? {})
    } as Record<string, string>
  });
  useProfilingStore.setState(state => {
    const newState = { ...state };
    delete newState[measurementKey];
    return newState;
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const measureAction = async <F extends (...args: any[]) => any, Key extends MeasurementKey>(
  cb: F,
  measurementKey: Key,
  contextParam?: MeasurementContextType<Key>
): Promise<ReturnType<F>> => {
  try {
    startMeasurement(measurementKey, contextParam);
    const result = await cb();
    endMeasurement(measurementKey, contextParam);
    return result;
  } catch (e) {
    logError(new ErrorWithContext(`${e}`, { measurementKey: measurementKey, ...contextParam }));
    throw e;
  }
};

const measureFromNavigationStart = <Key extends MeasurementKey>(measurementKey: Key, context?: MeasurementContextType<Key>) => {
  const state = useProfilingStore.getState();
  const navigationStart = state.lastNavigationStartTimestamp ?? 0;
  const endTimestamp = performance.now();
  measure(measurementKey, endTimestamp - navigationStart, {
    context: {
      ...(context ?? {})
    } as Record<string, string>
  });
};

const navigate = () => {
  const navigationStart = performance.now();
  useProfilingStore.setState({
    lastNavigationStartTimestamp: navigationStart
  });
};

export const profilingStoreActions = {
  startMeasurement,
  endMeasurement,
  measureAction,
  measureFromNavigationStart,
  navigate
};
