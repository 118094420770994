import { createNonPersistentStore } from 'lib/zustand';
import { SentenceMark } from 'modules/sdk/lib';
import { ListeningDependencies } from 'modules/sdk/listeningDependencies';

type BaseFields = {
  state: string;
  enabled: boolean;
};

type NotInitializedState = BaseFields & {
  state: 'not_initialized';
};

type InitializedState = BaseFields & {
  cleanUpFunction: () => void;
};

type InactiveState = InitializedState & {
  state: 'inactive';
};

type ActiveState = InitializedState & {
  state: 'active';
  sentenceMarks: SentenceMark[];
};

type State = NotInitializedState | InactiveState | ActiveState;

export const useClickToListenStore = createNonPersistentStore<State>(() => {
  return {
    state: 'not_initialized',
    enabled: false
  };
});

export const initializeClickToListen = async (listeningDependencies: ListeningDependencies) => {
  const { overlayInfo } = listeningDependencies;

  const cleanUp = overlayInfo.listenToLatestVisibleSentenceMarks(updateVisibleSpeechSentenceMarks);

  useClickToListenStore.setState(prevState => {
    if (prevState.state === 'active') {
      return {
        ...prevState,
        state: 'active',
        cleanUpFunction: cleanUp
      };
    }
    return {
      state: 'inactive',
      cleanUpFunction: cleanUp
    };
  });
};

const enableClickToListen = () => {
  useClickToListenStore.setState(prevState => ({
    ...prevState,
    enabled: true
  }));
};

const disableClickToListen = () => {
  useClickToListenStore.setState(prevState => ({
    ...prevState,
    enabled: false
  }));
};

const updateVisibleSpeechSentenceMarks = (visibleSpeechSentenceMarks: SentenceMark[]) => {
  useClickToListenStore.setState(prevState => ({
    ...prevState,
    state: 'active',
    sentenceMarks: visibleSpeechSentenceMarks
  }));
};

export const clickToListenStoreActions = {
  initializeClickToListen,
  enableClickToListen,
  disableClickToListen,
  updateClickToListenSentenceRects: updateVisibleSpeechSentenceMarks
};
