import CanvasLmsIcon from 'assets/icons/canvas-lms';
import DropboxIcon from 'assets/icons/integrations/dropbox';
import GoogleDriveIcon from 'assets/icons/integrations/google-drive';
import OneDriveIcon from 'assets/icons/integrations/one-drive';
import { TranslationKeys } from 'hooks/useTypedTranslation';
import { ALLOWED_MIME_TYPES } from 'interfaces/import';
import { IIntegrationFolder, INTEGRATION_ROOT_FOLDER, IntegrationFileType, IntegrationService } from 'interfaces/integrations';

export const IntegrationIcons: Record<IntegrationService, (props: React.SVGProps<SVGSVGElement>) => React.ReactNode> = {
  [IntegrationService.GOOGLE_DRIVE]: GoogleDriveIcon,
  [IntegrationService.DROPBOX]: DropboxIcon,
  [IntegrationService.CANVAS]: CanvasLmsIcon,
  [IntegrationService.ONE_DRIVE]: OneDriveIcon
};

export const IntegrationTitles: Record<IntegrationService, { name: TranslationKeys['common']; message: TranslationKeys['common'] }> = {
  [IntegrationService.GOOGLE_DRIVE]: {
    name: 'Google Drive',
    message: 'Lets you listen to your Drive files'
  },
  [IntegrationService.DROPBOX]: {
    name: 'Dropbox',
    message: 'Dropbox speaks out loud'
  },
  [IntegrationService.CANVAS]: {
    name: 'Canvas',
    message: 'Listen to your school readings'
  },
  [IntegrationService.ONE_DRIVE]: {
    name: 'One Drive',
    message: 'Listen to your One Drive files'
  }
};

export const ROOT_FOLDER_ID = INTEGRATION_ROOT_FOLDER;

export const SHARED_FOLDER_ID = 'shared';

export const SHARED_FOLDER: IIntegrationFolder = {
  id: SHARED_FOLDER_ID,
  name: 'Shared with me',
  directory: null,
  isShared: true,
  createdTime: null,
  type: IntegrationFileType.FOLDER
};

export const AUTO_IMPORT_LOCAL_MIME_TYPES: string[] = [ALLOWED_MIME_TYPES.PDF];

export const AUTO_IMPORT_REMOTE_MIME_TYPES: string[] = [
  ...AUTO_IMPORT_LOCAL_MIME_TYPES,
  ALLOWED_MIME_TYPES.GDOC,
  ALLOWED_MIME_TYPES.EPUB,
  ALLOWED_MIME_TYPES.TXT
];

export const CLASSIC_READER_MIME_TYPES: string[] = [ALLOWED_MIME_TYPES.EPUB, ALLOWED_MIME_TYPES.TXT, ALLOWED_MIME_TYPES.ALL_TEXT];

export const NEW_INSTANT_LISTENING_SUPPORTED_MIME_TYPES: string[] = [
  ALLOWED_MIME_TYPES.PDF,
  ALLOWED_MIME_TYPES.EPUB,
  ALLOWED_MIME_TYPES.TXT,
  ALLOWED_MIME_TYPES.HTML
] as const;

export const INTEGRATION_FILES_SETTINGS_KEY: Record<IntegrationService, string> = {
  [IntegrationService.GOOGLE_DRIVE]: 'Imported_File_IDs_From_Google_Drive',
  [IntegrationService.DROPBOX]: 'Imported_File_IDs_From_Dropbox',
  [IntegrationService.ONE_DRIVE]: 'Imported_File_IDs_From_Canvas',
  [IntegrationService.CANVAS]: 'Imported_File_IDs_From_One_Drive'
};
