import type { IUser } from 'interfaces';
import { currentUserEmitter } from 'modules/auth/store/authUserEmitter';
import type { SDKSubscriptionFacade } from 'modules/sdk/lib';
import { store as reduxStore } from 'store';

import { useSubscriptionStore } from '../subscriptionStore';

let cleanUpFunctions: (() => void)[] = [];

const cleanUp = () => {
  cleanUpFunctions.forEach(fn => fn());
  cleanUpFunctions = [];
};

export const initializeSubscriptionStore = (subscription: SDKSubscriptionFacade) => {
  cleanUp();

  const handler = (user: IUser | null) => {
    if (!user) {
      return;
    }

    const entitlements = subscription.mapSDKEntitlementsToEntitlementsInfo(user.entitlements);
    useSubscriptionStore.setState({
      entitlements,
      isNewUser: subscription.isNewUser(user),
      isLoading: false,
      isOnTrial: subscription.isOnTrial(user),
      isPremium: subscription.isPremium(user),
      isStripe: subscription.isStripe(user),
      firstPdfItemId: user.firstPdfDocument,
      mobileAppInstalled: user.usage?.stats.android != null || user.usage?.stats.iOS != null
    });
  };

  cleanUpFunctions.push(
    currentUserEmitter.on('onUserUpdate', ({ user }: { user: IUser | null }) => {
      try {
        //  Firebase can revoke user proxy object, so we need to catch it by trying to do a read once
        // in case an error is thrown, we do nothing and just wait for the next update
        user?.entitlements;
        handler(user);
      } catch (e) {
        // do nothing on error because sometimes Firebase can revoke user proxy somehow
      }
    })
  );

  handler(reduxStore.getState().auth?.user);
};
