import { initializeApp } from 'firebase/app';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  initializeFirestore,
  onSnapshot,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { IListeningProgress, IRecord, ISearchResult, ItemType, IUser, RecordStatus, RecordType } from 'interfaces';
import { IGamification, WeeklyStreakProgress } from 'interfaces/gamification';
import { UserUsageData as UserUsageDataInternal, UserUsageDataPlatform } from 'interfaces/usage';
import { isEmpty, isUndefined } from 'lodash';
import { hideProgress, showProgress } from 'utils';
import { LIFETIME_SUBSCRIPTION_PLAN_NAMES } from 'utils/constants';
import { DAY_NAMES, formatDateInYYYYMMDD, getPreviousSunday } from 'utils/dates';

import { SyncedListeningProgress } from '@speechifyinc/multiplatform-sdk';
import {
  AppEnvironment,
  ClientConfig,
  ClientOptions,
  ContentTextPosition,
  FirebaseDynamicLinksConfig,
  SpeechifyClientFactory,
  SpeechifyVersions,
  SpeechSentence
} from '@speechifyinc/multiplatform-sdk/api';
import { ConsoleDiagnosticReporter, SpeechifySDKDiagnostics } from '@speechifyinc/multiplatform-sdk/api/diagnostics';
import { UserUsageData as UserUsageDataType, UserUsageDataEntry as UserUsageDataEntryType } from '@speechifyinc/multiplatform-sdk/api/services/adoption/models';
import { ImportOptions } from '@speechifyinc/multiplatform-sdk/api/services/importing/models';
import {
  ContentType,
  FilterAndSortOptions,
  FilterType,
  ItemStatus,
  LibraryItem as LibraryItemType,
  RecordType as SDKRecordType,
  SearchRequest,
  SortBy as SortByType,
  SortOrder as SortOrderType,
  UpdateLibraryItemParams
} from '@speechifyinc/multiplatform-sdk/api/services/library/models';
import {
  BillingDashboardOptions,
  Entitlements as EntitlementsType,
  OneClickRenewal,
  OneClickRenewalStatus,
  Subscription as SubscriptionType,
  SubscriptionSource,
  SubscriptionStatus
} from '@speechifyinc/multiplatform-sdk/api/services/subscription/models';
import { SpeechifySDKTelemetry } from '@speechifyinc/multiplatform-sdk/api/telemetry';
import { LiveQueryView, Result } from '@speechifyinc/multiplatform-sdk/api/util';
import { Nullable } from '@speechifyinc/multiplatform-sdk/multiplatform-sdk-multiplatform-sdk';

import packageInfo from '../../../package.json';
import { logError } from '../observability';
import { WebAdapterFactory } from './adaptors';
import { WebBoundaryMap } from './adaptors/boundarymap';
import { WebFile } from './adaptors/file';
import { promisify } from './adaptors/promisify';
import type { User } from './auth';
import { Auth, OAuthCredential } from './auth';

SpeechifySDKDiagnostics.setupDiagnosticReporter(new ConsoleDiagnosticReporter());
SpeechifySDKTelemetry.enable();

const Success = Result.Success;

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FB_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FB_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FB_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FB_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FB_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FB_APP_ID,
  dynamicLinkDomain: process.env.NEXT_PUBLIC_FB_DYNAMIC_LINK_DOMAIN,
  iosBundleId: process.env.NEXT_PUBLIC_FB_IOS_BUNDLE_ID,
  iosAppstoreId: process.env.NEXT_PUBLIC_FB_IOS_APPSTORE_ID
};

const app = initializeApp(firebaseConfig);

// init sdk
const auth = new Auth(app);

initializeFirestore(app, { experimentalAutoDetectLongPolling: true });

const firestore = getFirestore(app);
const storage = getStorage(app);
const sdkVersion = SpeechifyVersions.SDK_VERSION;

export type Entitlements = EntitlementsType;
export type LibraryItem = LibraryItemType;
export type SortBy = SortByType;
export type SortOrder = SortOrderType;
export type Subscription = SubscriptionType;
export type UserUsageData = UserUsageDataType;
export type UserUsageDataEntry = UserUsageDataEntryType;

export {
  auth,
  BillingDashboardOptions,
  firestore,
  ImportOptions,
  OAuthCredential,
  OneClickRenewal,
  Result,
  sdkVersion,
  storage,
  SubscriptionSource,
  SubscriptionStatus,
  Success,
  Timestamp,
  UpdateLibraryItemParams,
  WebBoundaryMap,
  WebFile
};

let speechifyClientFactory: SpeechifyClientFactory | undefined = undefined;

export const getSpeechifyClient = () => {
  if (typeof window === 'undefined') {
    return;
  }

  const platform = new WebAdapterFactory(app);

  const clientOptions = new ClientOptions().enableLiveQueryViewV2().enableAudioServerV2();

  if (!speechifyClientFactory) {
    speechifyClientFactory = new SpeechifyClientFactory(
      new ClientConfig(
        AppEnvironment.WEB_APP,
        packageInfo.version || '0.0.0',
        process.env.NEXT_PUBLIC_PAYMENT_SERVER_URL!,
        `https://us-central1-${process.env.NEXT_PUBLIC_FB_PROJECT_ID}.cloudfunctions.net`,
        process.env.NEXT_PUBLIC_AUDIO_SERVER_URL!,
        process.env.NEXT_PUBLIC_VOICES_SERVER!.replace(/\/v\d+/, ''), // exclude version
        process.env.NEXT_PUBLIC_PLATFORM_CATALOG_URL!,
        process.env.NEXT_PUBLIC_FB_PROJECT_ID!,
        new FirebaseDynamicLinksConfig(
          process.env.NEXT_PUBLIC_DL_KEY!,
          'https://speechify.page.link',
          'com.cliffweitzman.speechify2',
          'com.cliffweitzman.speechifyMobile2'
        ),
        process.env.NEXT_PUBLIC_WEBAPP_URL!,
        process.env.NEXT_PUBLIC_PLATFORM_ML_PAGE_PARSING_URL!,
        clientOptions,
        process.env.NEXT_PUBLIC_AI_CHAT_URL!
      ),
      platform
    );
  }

  return speechifyClientFactory.getClient();
};

// ESLint: Optional chain expressions can return undefined by design - using a non-null assertion is unsafe and wrong
// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
export const accountSettingsService = getSpeechifyClient()?.accountSettingsService!;

// ESLint: Optional chain expressions can return undefined by design - using a non-null assertion is unsafe and wrong
// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
const ecosystemAdoptionService = getSpeechifyClient()?.ecosystemAdoptionService!;
export const getUserUsageData = promisify(ecosystemAdoptionService?.getUserUsageData.bind(ecosystemAdoptionService));

// import
// ESLint: Optional chain expressions can return undefined by design - using a non-null assertion is unsafe and wrong
// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
const importService = getSpeechifyClient()?.importService!;
export const importFileFromURL = promisify(importService?.importFileFromURL.bind(importService));
export const importBlobByUpload = promisify(importService?.importBlobByUpload.bind(importService));

// personal voices service
// ESLint: Optional chain expressions can return undefined by design - using a non-null assertion is unsafe and wrong
// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
const personalVoicesService = getSpeechifyClient()?.personalVoiceService!;
export const getPersonalVoices = promisify(personalVoicesService?.getPersonalVoiceSpecs.bind(personalVoicesService));
export const deletePersonalVoice = promisify(personalVoicesService?.deleteVoice.bind(personalVoicesService));

// library service
// ESLint: Optional chain expressions can return undefined by design - using a non-null assertion is unsafe and wrong
// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
const libraryService = getSpeechifyClient()?.libraryService!;
export const addDefaultLibraryItems = promisify(libraryService?.addDefaultLibraryItems.bind(libraryService));
export const archiveItem = promisify(libraryService?.archiveItem.bind(libraryService));
export const createFolder = promisify(libraryService?.createFolder.bind(libraryService));
export const deleteAllArchivedItems = promisify(libraryService?.deleteAllArchivedItems.bind(libraryService));
export const deleteItem = promisify(libraryService?.deleteItem.bind(libraryService));
export const getTopLevelArchivedItems = promisify(libraryService?.getTopLevelArchivedItems.bind(libraryService));
export const getChildrenItems = promisify(libraryService?.getChildrenItems.bind(libraryService));
export const getItem = promisify(libraryService?.getItem.bind(libraryService));
export const getRootFolder = promisify(libraryService?.getRootFolder.bind(libraryService));
export const getTopItems = promisify(libraryService?.getTopItems.bind(libraryService));
export const moveItem = promisify(libraryService?.moveItem.bind(libraryService));
export const restoreItem = promisify(libraryService?.restoreItem.bind(libraryService));
export const search = promisify(libraryService?.search.bind(libraryService));
export const shareItem = promisify(libraryService?.shareFile.bind(libraryService));
export const updateItem = promisify(libraryService?.updateItem.bind(libraryService));
export const getCount = promisify(libraryService?.getCount.bind(libraryService));

// subscription service
// ESLint: Optional chain expressions can return undefined by design - using a non-null assertion is unsafe and wrong
// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
export const subscriptionService = getSpeechifyClient()?.subscriptionService!;
export const cancelSubscription = promisify(subscriptionService?.cancelSubscriptionById.bind(subscriptionService));
export const extendTrial = promisify(subscriptionService?.extendTrial.bind(subscriptionService));
export const skipTrial = promisify(subscriptionService?.skipTrial.bind(subscriptionService));
export const getBillingDashboardUrl = promisify(subscriptionService?.getBillingDashboardUrl.bind(subscriptionService));
export const getOneClickRenewalStatus = promisify(subscriptionService?.getOneClickRenewalStatus.bind(subscriptionService));
export const getAllSubscriptionsAndEntitlements = promisify(subscriptionService?.getAllSubscriptions.bind(subscriptionService));
export const logHdWordsListened = promisify(subscriptionService?.logHdWordsListened.bind(subscriptionService));
export const performOneClickRenew = promisify(subscriptionService?.performOneClickRenew.bind(subscriptionService));

export const fetchUserUsageData = async (): Promise<UserUsageDataInternal> => {
  const userUsageData = await getUserUsageData();
  const userDataUsageMap = Object.values(UserUsageDataPlatform).map(platform => {
    const dataEntry: Nullable<UserUsageDataEntry> = userUsageData[platform];

    return [platform, dataEntry ? { lastVersion: dataEntry.lastVersion, lastVisitISODate: dataEntry.lastVisitISODate } : null];
  });

  return Object.fromEntries(userDataUsageMap);
};

export const canUpgrade = (currentUser: IUser): boolean => {
  if (!hasSubscription(currentUser)) return true;
  if (!isPremium(currentUser)) return true;

  return false;
};

// TODO(overhaul): Refactor to reuse getUpgradeURL from modules/subscription/utils instead
export const getUpgradeURL = (source: string = 'webapp_dashboard_upgrade'): string =>
  `${process.env.NEXT_PUBLIC_SAME_DOMAIN_ONBOARDING_URL}/onboarding/sso/?source=${source}`;
export const getDirectPurchaseURL = (source: string = 'webapp_dashboard_upgrade'): string =>
  `${process.env.NEXT_PUBLIC_SAME_DOMAIN_ONBOARDING_URL}/onboarding/sso/?source=${source}&directpurchase=true`;
export const hasSubscription = (currentUser: Nullable<IUser>) => !isEmpty(currentUser?.subscription);
export const isApple = (currentUser: IUser) =>
  currentUser?.subscription?.plan?.source && currentUser?.subscription?.plan?.source.name === SubscriptionSource.APPLE.name;
export const isCanceled = (currentUser: IUser) =>
  !isEmpty(currentUser?.subscription) && currentUser?.subscription?.status && currentUser?.subscription?.status.name === SubscriptionStatus.CANCELED.name;
export const isExpired = (currentUser: IUser) =>
  !isEmpty(currentUser?.subscription) && currentUser?.subscription?.status && currentUser?.subscription?.status.name === SubscriptionStatus.EXPIRED.name;
export const isOnTrial = (currentUser: IUser) => currentUser?.subscription?.isOnTrial === true;
export const isPayPal = (currentUser: IUser) =>
  currentUser?.subscription?.plan?.source && currentUser?.subscription?.plan?.source.name === SubscriptionSource.PAYPAL.name;
export const isPremium = (currentUser: Nullable<IUser>) => hasSubscription(currentUser) && currentUser?.entitlements?.isPremium === true;
export const isPlayStore = (currentUser: IUser) =>
  currentUser?.subscription?.plan?.source && currentUser?.subscription?.plan?.source.name === SubscriptionSource.PLAY_STORE.name;
export const isStripe = (currentUser: IUser) =>
  currentUser?.subscription?.plan?.source && currentUser?.subscription?.plan?.source.name === SubscriptionSource.STRIPE.name;
export const isLifetime = (currentUser: IUser) =>
  currentUser?.subscription?.plan?.name && LIFETIME_SUBSCRIPTION_PLAN_NAMES.includes(currentUser.subscription.plan.name);
export const isMonthlySubscription = (currentUser: IUser) => currentUser?.subscription?.plan?.renewalFrequency.name === 'MONTHLY';
export const isYearlySubscription = (currentUser: IUser) => currentUser?.subscription?.plan?.renewalFrequency.name === 'YEARLY';
export const isAnonymous = (user: User) => user.isAnonymous || (!user.email && !user.phoneNumber && (user.providerData?.length ?? 0) === 0);
export const wordsAllotment = (currentUser: IUser) => currentUser?.entitlements?.nextHDWordsGrant || 150000;
export const wordsLeft = (currentUser: IUser) => currentUser?.entitlements?.hdWordsLeft || 0;
export const subscriptionPrice = (currentUser: IUser) => currentUser?.subscription?.plan?.price;
export const isRewardSubscription = (currentUser: IUser) => currentUser?.subscription?.plan?.name === 'price_1KtFqaBtf7hakIXC0tn5nKju';
export const subscriptionCurrency = (currentUser: IUser) => currentUser?.subscription?.currency;

// ESLint: 'source' is assigned a value but never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const renewCancelledSubscription = async (source: string = 'webapp-resume-button'): Promise<void> => {
  await performOneClickRenew(new OneClickRenewal.Stripe(null, null));
};

// ESLint: 'source' is assigned a value but never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const willChargeUserForRenewal = async (source: string = 'webapp-resume-button'): Promise<boolean | null> => {
  const renewalStatus = await getOneClickRenewalStatus(new OneClickRenewal.Stripe(null, null));

  if (renewalStatus instanceof OneClickRenewalStatus.Available) {
    const { willChargeUser } = renewalStatus;
    return willChargeUser === true;
  }

  return null;
};

const destroyLiveQueryView = (view: LiveQueryView<LibraryItem> | undefined, destructor?: () => void) => {
  if (destructor) {
    destructor();
  }

  if (view) {
    view.destroy();
  }
};

export const fetchArchivedItems = async (): Promise<IRecord[]> => {
  const view = await getTopLevelArchivedItems();
  return (await parseItemView(view, { parentFolderId: null })).filter(item => item.type !== ItemType.Folder);
};

const fetchFolders = async (
  sortBy: SortBy = SortByType.DATE_ADDED,
  sortOrder: SortOrder = SortOrderType.DESC
): Promise<{ folders: IRecord[]; view: LiveQueryView<LibraryItem> }> => {
  const rootFolderId = await getRootFolder();

  const view = await getTopItems(new FilterAndSortOptions(FilterType.FOLDERS, sortBy, sortOrder), 5000);
  const folders = await parseItemView(view, { rootFolderId });

  return { folders, view };
};

const fetchItemsByFolderId = async (
  currentFolderId: string,
  filterType: FilterType = FilterType.RECORDS,
  sortBy: SortBy = SortByType.DATE_ADDED,
  sortOrder: SortOrder = SortOrderType.DESC
): Promise<{ items: IRecord[]; view: LiveQueryView<LibraryItem> }> => {
  const rootFolderId = await getRootFolder();

  // If the currentFolderId is 'trash', set folderId to rootFolderId
  // because the trash folder is a custom web app folder and not part of the SDK.
  const folderId = currentFolderId === 'trash' ? rootFolderId : currentFolderId;

  const view = await getChildrenItems(folderId, new FilterAndSortOptions(filterType, sortBy, sortOrder));
  const items = (await parseItemView(view, { loadAll: true, rootFolderId })).filter(item => item.type !== ItemType.Folder);

  return { items, view };
};

export const isExperienceReady = (item: IRecord): boolean => {
  return item && !isUndefined(item.characterIndex) && Boolean(item.recordType);
};

const itemContentTypeToRecordType = (contentType: ContentType): RecordType => {
  switch (contentType) {
    case ContentType.DOCX:
      return RecordType.FILE;

    case ContentType.HTML:
      return RecordType.WEB;

    case ContentType.PDF:
      return RecordType.PDF;

    case ContentType.SCAN:
      return RecordType.SCAN;

    case ContentType.TXT:
      return RecordType.TXT;

    case ContentType.EPUB:
      return RecordType.EPUB;
  }

  return RecordType.DEFAULT;
};

const itemStatusToRecordStatus = (itemStatus: ItemStatus): RecordStatus => {
  switch (itemStatus) {
    case ItemStatus.DONE:
      return RecordStatus.Success;

    case ItemStatus.FAILED:
      return RecordStatus.Failed;

    case ItemStatus.PROCESSING:
      return RecordStatus.Processing;

    default:
      return RecordStatus.Failed;
  }
};

export const libraryItemToIRecord = (libraryItem: LibraryItem, options: { rootFolderId?: string; parentFolderId?: string | null } = {}): IRecord => {
  const isParentRootFolder = !!options.rootFolderId && libraryItem.parentFolderId === options.rootFolderId;
  const itemParentFolderId = isParentRootFolder ? null : libraryItem.parentFolderId;
  const parentFolderId = options.parentFolderId !== undefined ? options.parentFolderId : itemParentFolderId;

  if (libraryItem instanceof LibraryItemType.Folder) {
    const { childrenCount, coverImageUrl, createdAt, id, ownerId, title, updatedAt } = libraryItem as LibraryItemType.Folder;

    return parseUnserializables({
      childrenCount,
      coverImagePath: coverImageUrl,
      createdAt: new Date(createdAt),
      id,
      isSDK: true,
      owner: ownerId,
      parentFolderId,
      title,
      type: ItemType.Folder,
      updatedAt: new Date(updatedAt)
    }) as IRecord;
  }

  let contentObject;

  const isLocalContent = libraryItem instanceof LibraryItemType.DeviceLocalContent;

  if (isLocalContent) {
    const { contentType, coverImageUrl, createdAt, ownerId, listenProgressStatus, libraryImportProgress, sourceUrl, status, title, updatedAt } =
      libraryItem as LibraryItemType.DeviceLocalContent;

    contentObject = {
      coverImagePath: coverImageUrl,
      createdAt: new Date(createdAt),
      id: libraryItem.uri.id,
      isSDK: true,
      libraryImportProgress,
      owner: ownerId,
      parentFolderId,
      listenProgressStatus: listenProgressStatus?.name,
      recordType: itemContentTypeToRecordType(contentType!),
      sourceURL: sourceUrl,
      status: itemStatusToRecordStatus(status),
      title,
      type: ItemType.Record,
      updatedAt: new Date(updatedAt)
    };
  } else {
    const {
      contentType,
      coverImageUrl,
      createdAt,
      excerpt,
      id,
      listeningProgress,
      ownerId,
      listenProgressStatus,
      listenProgressPercent,
      libraryImportProgress,
      sourceUrl,
      sourceStoredUrl,
      status,
      title,
      totalWords,
      updatedAt
    } = libraryItem as LibraryItemType.Content;

    contentObject = {
      coverImagePath: coverImageUrl,
      createdAt: new Date(createdAt),
      excerpt,
      id,
      isSDK: true,
      listeningProgress: parseListeningProgress(listeningProgress),
      owner: ownerId,
      parentFolderId,
      listenProgressStatus: listenProgressStatus?.name,
      progressPercent: listenProgressPercent ? Math.round(listenProgressPercent * 100) : 0,
      libraryImportProgress,
      recordType: itemContentTypeToRecordType(contentType),
      sourceURL: sourceStoredUrl ?? sourceUrl,
      originalSourceURL: sourceUrl,
      status: itemStatusToRecordStatus(status),
      title,
      type: ItemType.Record,
      updatedAt: new Date(updatedAt),
      wordCount: totalWords
    };
  }

  return parseUnserializables(contentObject) as IRecord;
};

export const fetchAllRecordItemsOwnedByCurrentUser = async () => {
  const type = FilterType.RECORDS.Companion.all();
  const count = await getCount(type);
  return count;
};

export const fetchItem = async (itemId: string, parentFolderId?: string) => {
  const item = libraryItemToIRecord(await getItem(itemId), { parentFolderId });

  item.characterIndex = item.listeningProgress?.cursor?.characterIndex || 0;

  return { ...parseUnserializables(item), id: itemId };
};

export const fetchSharedItem = async (itemId: string) => {
  const isLegacy = await isLegacySharedItem(itemId);

  if (isLegacy) {
    const legacyShareItem = await getLegacySharedItem(itemId);

    return { ...parseUnserializables(legacyShareItem), id: itemId };
  } else {
    const itemSnapshot = await getDoc(doc(firestore, 'items', itemId));

    return { ...parseUnserializables(itemSnapshot.data()), id: itemId };
  }
};

export const fetchUserHDWordsGrantExperiement = async (userId: string): Promise<string | undefined> => {
  const snapshot = await getDoc(doc(firestore, 'accountSettings', userId));

  if (snapshot.exists()) {
    return snapshot.data()?.experiments?.['hd-words-grant'];
  }
};

export const getDeviceAdoptionInfo = async (user_id: string) => {
  const response = await fetch(`https://us-central1-${process.env.NEXT_PUBLIC_FB_PROJECT_ID}.cloudfunctions.net/queryEcosystemInfo`, {
    method: 'POST',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
    body: JSON.stringify({ user_id })
  });

  return await response.json();
};

export const getLegacySharedItem = async (itemId: string): Promise<$TSFixMe> => {
  const legacySharedItemSnapshot = await getDoc(doc(firestore, 'sharedItems', itemId));

  if (legacySharedItemSnapshot.exists()) {
    return legacySharedItemSnapshot.data();
  }
};

export const importSharedItemForUser = async (sharedItemId: string, userId: string, newItemId: string): Promise<string> => {
  const response: string = await fetch(`https://us-central1-${process.env.NEXT_PUBLIC_FB_PROJECT_ID}.cloudfunctions.net/copyLibraryItemV2`, {
    method: 'post',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
    body: JSON.stringify({
      currentRecordUid: sharedItemId,
      userId,
      newRecordUid: newItemId
    })
  })
    .then(response => response.json())
    .then(result => result.message);

  return response;
};

export const isLegacySharedItem = async (itemId: string): Promise<boolean> => {
  try {
    const legacySharedItemSnapshot = await getDoc(doc(firestore, 'sharedItems', itemId));

    if (legacySharedItemSnapshot.exists()) {
      return true;
    } else {
      return false;
    }
  } catch (ex) {
    logError(ex as Error);
    return false;
  }
};

const parseItemView = async (
  view: LiveQueryView<LibraryItem>,
  { loadAll = false, parentFolderId, rootFolderId }: { loadAll?: boolean; parentFolderId?: string | null; rootFolderId?: string } = {}
) => {
  let moreView: Nullable<LiveQueryView<LibraryItem>>;
  let counter = 0;

  if (loadAll) {
    do {
      moreView = await promisify(view.loadMoreItems.bind(view))();
      counter++;
    } while (moreView && counter < 5); // limit of 5 * 100 items
  }

  const items = await promisify(view.getCurrentItems.bind(view))();
  return items.map(item => libraryItemToIRecord(item, { parentFolderId, rootFolderId }));
};

const parseListeningProgress = (progress: Nullable<SyncedListeningProgress>): IListeningProgress | null => {
  if (!progress) return null;

  const cursor = progress.cursor as ContentTextPosition;
  const characterIndex = cursor?.characterIndex || 0;
  const fraction = progress.fraction || 0;
  const timestamp = progress.timestamp || '';

  return {
    cursor: {
      characterIndex
    },
    fraction,
    timestamp
  };
};

export const parseUnserializables = (obj: $TSFixMe) => {
  let continueParsingDates = true;

  const newObj = JSON.parse(JSON.stringify({ ...obj }));

  if (newObj.createdAt && newObj.createdAt.seconds && newObj.createdAt.nanoseconds) {
    if (!newObj.createdAt._seconds) {
      newObj.createdAt._seconds = newObj.createdAt.seconds;
    }

    if (!newObj.createdAt._nanoseconds) {
      newObj.createdAt._nanoseconds = newObj.createdAt.nanoseconds;
    }
  }

  if (newObj.updatedAt && newObj.updatedAt.seconds && newObj.updatedAt.nanoseconds) {
    if (!newObj.updatedAt._seconds) {
      newObj.updatedAt._seconds = newObj.updatedAt.seconds;
    }

    if (!newObj.updatedAt._nanoseconds) {
      newObj.updatedAt._nanoseconds = newObj.updatedAt.nanoseconds;
    }
  }

  if (newObj.createdAt && newObj.createdAt._seconds && newObj.createdAt._nanoseconds) {
    if (newObj.updatedAt && newObj.updatedAt._seconds && newObj.updatedAt._nanoseconds) {
      continueParsingDates = false;
    }
  }

  if (continueParsingDates) {
    if (newObj.createdAt && newObj.createdAt instanceof Timestamp) newObj.createdAt = parseTimestamp(newObj.createdAt);
    if (newObj.updatedAt && newObj.updatedAt instanceof Timestamp) newObj.updatedAt = parseTimestamp(newObj.updatedAt);

    if (newObj.createdAt && !(newObj.createdAt instanceof Timestamp)) newObj.createdAt = parseTimestamp(Timestamp.fromDate(new Date(newObj.createdAt)));

    if (newObj.updatedAt && !(newObj.updatedAt instanceof Timestamp)) newObj.updatedAt = parseTimestamp(Timestamp.fromDate(new Date(newObj.updatedAt)));
  }

  return newObj;
};

const parseTimestamp = (timestamp: Timestamp) => {
  return Object.entries(timestamp).reduce((accum, [name, value]) => {
    return { ...accum, [`_${name}`]: value };
  }, {});
};

export const searchItems = async (query: string): Promise<ISearchResult[]> => {
  if (!query) return [];
  const searchResults = await search(new SearchRequest(query, [FilterType.RECORDS.Companion.all()]));
  return searchResults.items.map(({ coverImageUrl, uri, title }) => ({
    coverImageUrl: coverImageUrl || undefined,
    id: uri.id,
    title
  }));
};

export const getIntegration = async (userId: string, provider: string) => {
  if (!userId) return null;
  const item = await getDocs(query(collection(firestore, 'integrationCredentials'), where('userId', '==', userId), where('provider', '==', provider)));
  if (!item.empty && item.size > 0) {
    const firstItem = item.docs.at(0);
    if (firstItem) {
      return { id: firstItem.id, ...firstItem.data() };
    }
  }
  return null;
};

export const setCanvasIntegration = async (baseUrl: string, accessToken: string, userId: string) => {
  if (!baseUrl || !accessToken || !userId) throw new Error('Please provide baseUrl and accessToken');

  const item = await addDoc(collection(firestore, 'integrationCredentials'), {
    apiBaseUrl: baseUrl,
    provider: 'lms.canvas',
    token: accessToken,
    type: 'token',
    userId,
    last_synced: new Date()
  });

  return item;
};

export const deleteIntegration = (docId: string) => {
  if (!docId) throw new Error('Please provide docId');
  return deleteDoc(doc(firestore, 'integrationCredentials', docId));
};

export const reset1500FreeWords = async (userId: string): Promise<void> => {
  const document = await getDoc(doc(firestore, 'userRewards', userId));

  if (document.exists()) {
    await updateDoc(doc(firestore, 'userRewards', userId), { premiumWords: 1500 });
  }
};

let _itemView: LiveQueryView<LibraryItem> | undefined = undefined;
let _itemViewDestructor: (() => void) | undefined = undefined;

const subscribeToItemLiveQueryView = (view: LiveQueryView<LibraryItem>, rootFolderId: string, callback: (items: IRecord[]) => void) => {
  destroyLiveQueryView(_itemView, _itemViewDestructor);

  _itemView = view;

  _itemViewDestructor = _itemView.addChangeListener(async res => {
    if (res instanceof Success) {
      const items = await parseItemView(res.value, { rootFolderId });

      callback(items.filter(item => item.type !== ItemType.Folder));
    }
  });
};

export const subscribe = async (folderId: string, callback: (folders: IRecord[] | null, items: IRecord[] | null) => void) => {
  let folders: IRecord[] = [];
  let items: IRecord[] = [];

  showProgress();

  // items
  const itemResult = await fetchItemsByFolderId(folderId);

  // folders
  const rootFolderId = await getRootFolder();
  const folderResult = await fetchFolders();

  if (folderResult) {
    const folderView = folderResult.view;
    folders = folderResult.folders || [];

    destroyLiveQueryView(folderView);
  }

  hideProgress();

  if (itemResult) {
    const itemView = itemResult.view;
    items = itemResult.items || [];

    subscribeToItemLiveQueryView(itemView, rootFolderId, items => callback(null, items));
  }

  callback(folders, items);

  return unsubscribe;
};

export const subscribeToGamification = (userId: string, callback: (gamification: IGamification) => void) => {
  return onSnapshot(doc(firestore, 'gamification', userId), snapshot => {
    const isFromCache = snapshot.metadata.fromCache;
    if (!isFromCache) {
      if (snapshot.exists()) {
        const aggregateData = snapshot.data();

        // add weekly data
        const previousSunday = getPreviousSunday();
        const key = formatDateInYYYYMMDD(previousSunday);

        getDoc(doc(firestore, 'gamification', userId, 'weeklyData', key)).then(weeklySnapshot => {
          let derivedData = {};

          if (weeklySnapshot.exists()) {
            const data = weeklySnapshot.data();

            if (data) {
              const weeklyData = data.weeklyData ?? [];
              const currentWeekProgress: Partial<WeeklyStreakProgress> = {};

              DAY_NAMES.forEach((dayName, index) => {
                currentWeekProgress[dayName] = weeklyData[index];
              });

              const todayListeningDurationInMinutes = weeklyData[new Date().getDay()].listeningDurationInMinutes;

              derivedData = {
                currentWeekProgress: currentWeekProgress as WeeklyStreakProgress,
                todayListeningDurationInMinutes
              };
            }
          }

          callback(parseUnserializables({ ...aggregateData, ...derivedData }));
        });
      }
    }
  });
};

export const setDailyListeningGoal = async ({ userId, dailyListeningGoalInMinutes }: { userId: string; dailyListeningGoalInMinutes: number }) => {
  return await setDoc(
    doc(firestore, 'gamification', userId),
    {
      dailyListeningGoalInMinutes: dailyListeningGoalInMinutes, //
      timezoneOffset: new Date().getTimezoneOffset()
    },
    { merge: true }
  );
};

export const setStreakGoal = async ({ userId, streakGoalInDays }: { userId: string; streakGoalInDays: number }) => {
  return setDoc(doc(firestore, 'gamification', userId), { streakGoalInDays }, { merge: true });
};

export const getWeeklyStreakProgress = async ({ userId, dateKey }: { userId: string; dateKey: string }): Promise<WeeklyStreakProgress> => {
  const snapshot = await getDoc(doc(firestore, 'gamification', userId, 'weeklyData', dateKey));
  const currentWeekProgress: Partial<WeeklyStreakProgress> = {};

  let weeklyData: Array<{ listeningDurationInMinutes: number; percentage: number }> = [];

  if (snapshot.exists()) {
    const data = snapshot.data();

    if (data) {
      weeklyData = data.weeklyData ?? [];
    }
  }

  DAY_NAMES.forEach((dayName, index) => {
    currentWeekProgress[dayName] = weeklyData[index] || Object.assign({}, { listeningDurationInMinutes: 0, percentage: 0 });
  });

  return currentWeekProgress as WeeklyStreakProgress;
};

// ESLint: 'userId' is defined but never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getPDFCount = async (userId: string): Promise<number> => {
  const type = FilterType.RECORDS.Companion.ofTypes([SDKRecordType.PDF]);
  const size = await getCount(type);
  return size || 0;
};

// ESLint: Unexpected any & Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
export const toPOJO = (obj: EntitlementsType | SubscriptionType | any): EntitlementsType | SubscriptionType | any => {
  if (obj instanceof EntitlementsType) {
    const { isPremium, hdWordsLeft, nextHDWordsGrant, nextHDWordsGrantDate, maxSpeedInWordsPerMinute } = obj;
    return { isPremium, hdWordsLeft, nextHDWordsGrant, nextHDWordsGrantDate, maxSpeedInWordsPerMinute };
  } else if (obj instanceof SubscriptionType) {
    const { plan, isOnTrial, status, renewsAt, subscribedAt, currency, renewalStatus } = obj;
    const { source, name, price, renewalFrequency, conversionId, wordsLimit, discountIds, hasTrial, initialTrialDurationDays } = plan;

    const { name: renewalFrequencyName } = renewalFrequency;
    const { name: sourceName } = source;
    const { name: statusName } = status;
    const { name: renewalStatusName } = renewalStatus;

    return {
      plan: {
        source: { name: sourceName },
        name,
        price,
        renewalFrequency: { name: renewalFrequencyName },
        conversionId,
        wordsLimit,
        discountIds,
        hasTrial,
        initialTrialDurationDays
      },
      isOnTrial,
      status: { name: statusName },
      renewsAt,
      subscribedAt,
      currency,
      renewalStatus: { name: renewalStatusName }
    };
  } else {
    return obj;
  }
};

export const getTtsSubscriptionAndEntitlements = ({ subscriptions, entitlements }: { subscriptions: SubscriptionType[]; entitlements: EntitlementsType }) => {
  const ttsSubscription = subscriptions.find(sub => sub.plan?.productTypes.includes('tts')) ?? null;
  const ttsEntitlements = entitlements.copy();

  return { ttsSubscription, ttsEntitlements };
};

export const hasStudioSubscription = ({ subscriptions }: { subscriptions: SubscriptionType[] }) => {
  return subscriptions.some(
    sub => sub.plan?.productTypes.includes('voiceover') || sub.plan?.productTypes.includes('dubbing') || sub.plan?.productTypes.includes('voicecloning')
  );
};

export const generateAudioForDownload = async (
  blocks: SpeechSentence[],
  voice: {
    name: string;
    engine: string;
    language: string;
  }
) => {
  const MAX_BLOCK_LENGTH = 3000;
  const token = await auth.currentUser?.getIdToken();

  const opts = {
    format: 'mp3'
  };

  const specialCharacterReplace = (text: string) => text.replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('"', '"');

  const splitTextInHalfByWhitespace = (text: string): string[] => {
    const words = text.split(' ');
    const half = Math.ceil(words.length / 2);
    const firstHalf = words.slice(0, half).join(' ');
    const secondHalf = words.slice(half).join(' ');

    return [firstHalf, secondHalf];
  };

  const blocksToSend = blocks.reduce((result, block) => {
    const text = block?.text?.text ?? '';
    const convertedText = specialCharacterReplace(text);

    if (convertedText.length > MAX_BLOCK_LENGTH) {
      const splitText = splitTextInHalfByWhitespace(text);

      splitText.forEach(text => {
        result.push({
          id: result.length.toString(),
          voice: {
            name: voice.name,
            engine: voice.engine,
            language: voice.language
          },
          ssml: `<speak>${specialCharacterReplace(text)}</speak>`
        });
      });
    } else {
      result.push({
        id: result.length.toString(),
        voice: {
          name: voice.name,
          engine: voice.engine,
          language: voice.language
        },
        ssml: `<speak>${convertedText}</speak>`
      });
    }

    return result;
    // ESLint: Unexpected any. Specify a different type.eslint@typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, [] as any[]);

  const generateAudioEndpoint = 'generateContinuousAudio';

  return fetch(`${process.env.NEXT_PUBLIC_VOICEOVER_BACKEND_URL}/${generateAudioEndpoint}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({ blocks: blocksToSend, ...opts })
  })
    .then(res => {
      if (!res.ok) {
        if (res.status === 403) {
          throw new Error('Download is forbidden. You might have reached your download limit.');
        } else {
          throw new Error('Something went wrong during the downloading. Pls try again.');
        }
      }
      return res.blob();
    })
    .then(async res => {
      // koa has a unique behavior for wav files where its overriding the content type header that we are setting, this is temporarily handling that
      // to fix the bug in production, but we will find a way to fix it in koa itself
      if (res.type === 'audio/wave') {
        const newBlob = new Blob([await res.arrayBuffer()], {
          type: 'audio/wav'
        });
        const downloadUrl = URL.createObjectURL(newBlob);
        return downloadUrl;
      }
      const downloadUrl = URL.createObjectURL(res);
      return downloadUrl;
    });
};

const unsubscribe = () => {
  destroyLiveQueryView(_itemView, _itemViewDestructor);
};

export const getAuthTenantByDomain = async (domain: string) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_AUTH_SERVER}/saml/discovery?domain=${domain}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'speechify-app-environment': 'web-app'
      }
    });

    if (!response.ok) {
      return null;
    }

    const data = await response.json();

    return data.tenants[0];
  } catch (error) {
    return null;
  }
};
