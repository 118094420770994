import type { Property } from 'csstype';
import { logError } from 'lib/observability';
import { MultiplatformSDKInstance } from 'modules/sdk/lib/sdk';
import { Nullable } from 'utils/types';

import type { ContentText, StandardBlock, StandardElement } from '@speechifyinc/multiplatform-sdk';

type SDKLeafElement = StandardElement.Text | StandardElement.Image.Remote;

export enum ClassicElementInfoType {
  Anchor = 'Anchor',
  Bold = 'Bold',
  Code = 'Code',
  Heading = 'Heading',
  ImageLocal = 'ImageLocal',
  ImageRemote = 'ImageRemote',
  Italics = 'Italics',
  List = 'List',
  ListItem = 'ListItem',
  Paragraph = 'Paragraph',
  Table = 'Table',
  TableCell = 'TableCell',
  TableRow = 'TableRow',
  Text = 'Text',
  Underlined = 'Underlined'
}

export type TextElementMeta = {
  type: ClassicElementInfoType.Text;
  data: {
    text: string;
  };
};

export type ImageRemoteElementMeta = {
  type: ClassicElementInfoType.ImageRemote;
  data: {
    url: string;
    altText: Nullable<string>;
    width: Nullable<number>;
    height: Nullable<number>;
  };
};

export type HeadingElementMeta = {
  type: ClassicElementInfoType.Heading;
  data: {
    level: number;
  };
};

export type ParagraphElementMeta = {
  type: ClassicElementInfoType.Paragraph;
  data: null;
};

export type AnchorElementMeta = {
  type: ClassicElementInfoType.Anchor;
  data: {
    url: string;
  };
};

export type BoldElementMeta = {
  type: ClassicElementInfoType.Bold;
  data: null;
};

export type UnderlinedElementMeta = {
  type: ClassicElementInfoType.Underlined;
  data: null;
};

export type ItalicsElementMeta = {
  type: ClassicElementInfoType.Italics;
  data: null;
};

export type TableElementMeta = {
  type: ClassicElementInfoType.Table;
  data: null;
};

export type TableRowElementMeta = {
  type: ClassicElementInfoType.TableRow;
  data: null;
};

export type TableCellElementMeta = {
  type: ClassicElementInfoType.TableCell;
  data: {
    isHeader: boolean;
  };
};

export type CodeElementMeta = {
  type: ClassicElementInfoType.Code;
  data: null;
};

export type ListElementMeta = {
  type: ClassicElementInfoType.List;
  data: {
    listStyleType: Property.ListStyleType;
  };
};

export type ListItemElementMeta = {
  type: ClassicElementInfoType.ListItem;
  data: null;
};

export type LeafClassicElementInfoMeta = TextElementMeta | ImageRemoteElementMeta;
export type InternalClassicElementInfoMeta =
  | HeadingElementMeta
  | ParagraphElementMeta
  | AnchorElementMeta
  | BoldElementMeta
  | UnderlinedElementMeta
  | ItalicsElementMeta
  | TableElementMeta
  | TableRowElementMeta
  | TableCellElementMeta
  | CodeElementMeta
  | ListElementMeta
  | ListItemElementMeta;

export abstract class ClassicElementInfo {
  public readonly blockIndex: number;
  public readonly elementId: number;

  protected readonly sdk: MultiplatformSDKInstance;
  protected readonly sdkElement: StandardElement;

  private static lastElementId: number = 0;

  public static create(sdk: MultiplatformSDKInstance, blockIndex: number, sdkElement: StandardElement): ClassicElementInfo {
    const isLeaf = (sdkElement: StandardElement): sdkElement is SDKLeafElement => {
      const StandardElement = sdk.sdkModule.StandardElement;
      return sdkElement instanceof StandardElement.Text || sdkElement instanceof StandardElement.Image.Remote;
    };
    if (isLeaf(sdkElement)) {
      return new LeafClassicElementInfo(sdk, blockIndex, sdkElement);
    }
    return new InternalClassicElementInfo(sdk, blockIndex, sdkElement);
  }

  constructor(sdk: MultiplatformSDKInstance, blockIndex: number, sdkElement: StandardElement) {
    this.sdk = sdk;
    this.blockIndex = blockIndex;
    this.sdkElement = sdkElement;

    this.elementId = ClassicElementInfo.lastElementId++;
  }

  abstract isLeafElement(): this is LeafClassicElementInfo;

  public static collectAllElements = (element: ClassicElementInfo): ClassicElementInfo[] => {
    if (element.isLeafElement()) {
      return [element];
    }
    const internalElement = element as InternalClassicElementInfo;
    return [internalElement, ...internalElement.elements.flatMap(ClassicElementInfo.collectAllElements)];
  };

  public static collectAllLeafElements = (element: ClassicElementInfo): LeafClassicElementInfo[] => {
    return ClassicElementInfo.collectAllElements(element).filter((element): element is LeafClassicElementInfo => element.isLeafElement());
  };
}

export class InternalClassicElementInfo extends ClassicElementInfo {
  isLeafElement(): this is LeafClassicElementInfo {
    return false;
  }

  public readonly elements: ClassicElementInfo[];
  public readonly meta: InternalClassicElementInfoMeta;
  constructor(sdk: MultiplatformSDKInstance, blockIndex: number, sdkElement: StandardElement) {
    super(sdk, blockIndex, sdkElement);
    this.elements = sdkElement.elements.map(element => ClassicElementInfo.create(sdk, blockIndex, element));
    this.meta = this.getMeta() as InternalClassicElementInfoMeta;
  }

  private getMeta = (): InternalClassicElementInfoMeta => {
    const sdkElement = this.sdkElement;
    const StandardElement = this.sdk.sdkModule.StandardElement;
    if (sdkElement instanceof StandardElement.Heading) {
      return {
        type: ClassicElementInfoType.Heading,
        data: {
          level: sdkElement.level
        }
      };
    }
    if (sdkElement instanceof StandardElement.Paragraph) {
      return {
        type: ClassicElementInfoType.Paragraph,
        data: null
      };
    }
    // TODO(albertusdev): Add support for internal anchor
    if (sdkElement instanceof StandardElement.Anchor.External) {
      return {
        type: ClassicElementInfoType.Anchor,
        data: {
          url: sdkElement.url
        }
      };
    }
    if (sdkElement instanceof StandardElement.Bold) {
      return {
        type: ClassicElementInfoType.Bold,
        data: null
      };
    }
    if (sdkElement instanceof StandardElement.Underlined) {
      return {
        type: ClassicElementInfoType.Underlined,
        data: null
      };
    }
    if (sdkElement instanceof StandardElement.Italics) {
      return {
        type: ClassicElementInfoType.Italics,
        data: null
      };
    }
    if (sdkElement instanceof StandardElement.Table) {
      return {
        type: ClassicElementInfoType.Table,
        data: null
      };
    }
    if (sdkElement instanceof StandardElement.Table.Row) {
      return {
        type: ClassicElementInfoType.TableRow,
        data: null
      };
    }
    if (sdkElement instanceof StandardElement.Table.Cell) {
      return {
        type: ClassicElementInfoType.TableCell,
        data: {
          isHeader: sdkElement.isHeader
        }
      };
    }
    if (sdkElement instanceof StandardElement.Code) {
      return {
        type: ClassicElementInfoType.Code,
        data: null
      };
    }

    if (sdkElement instanceof StandardElement.List) {
      // return <ul style={{ listStyleType: block.listStyle.toString().replace(/_/g, '-') }}>{children}</ul>;
      return {
        type: ClassicElementInfoType.List,
        data: {
          listStyleType: sdkElement.listStyle.toString().replace(/_/g, '-')
        }
      };
    }
    if (sdkElement instanceof StandardElement.List.ListItem) {
      return {
        type: ClassicElementInfoType.ListItem,
        data: null
      };
    }

    logError(new Error(`Unexpected element type: ${sdkElement}`));
    return {
      type: ClassicElementInfoType.Code,
      data: null
    };
  };
}

export class LeafClassicElementInfo extends ClassicElementInfo {
  public readonly sdkText: ContentText;
  public readonly meta: LeafClassicElementInfoMeta;

  constructor(sdk: MultiplatformSDKInstance, blockIndex: number, sdkElement: SDKLeafElement) {
    super(sdk, blockIndex, sdkElement);
    this.sdkText = sdkElement.text;
    this.meta = this.getMeta();
  }

  isLeafElement(): this is LeafClassicElementInfo {
    return true;
  }

  private getMeta = (): LeafClassicElementInfoMeta => {
    const sdkElement = this.sdkElement;
    const StandardElement = this.sdk.sdkModule.StandardElement;
    if (sdkElement instanceof StandardElement.Text) {
      return {
        type: ClassicElementInfoType.Text,
        data: {
          text: sdkElement.text.text
        }
      };
    }
    if (sdkElement instanceof StandardElement.Image.Remote) {
      return {
        type: ClassicElementInfoType.ImageRemote,
        data: {
          url: sdkElement.url,
          altText: sdkElement.altText,
          width: sdkElement.width,
          height: sdkElement.height
        }
      };
    }
    logError(new Error(`Unexpected element type: ${sdkElement}`));
    return {
      type: ClassicElementInfoType.Text,
      data: {
        text: ''
      }
    };
  };
}

export enum ClassicBlockInfoType {
  Heading = 'Heading',
  List = 'List',
  Paragraph = 'Paragraph',
  Unknown = 'Unknown'
}

// TODO(albertusdev): Refactor and separate this into a separate file
export type BlockHeadingMeta = {
  type: ClassicBlockInfoType.Heading;
  data: {
    level: number;
  };
};

export type BlockParagraphMeta = {
  type: ClassicBlockInfoType.Paragraph;
  data: null;
};

export type BlockListMeta = {
  type: ClassicBlockInfoType.List;
  data: {
    isNumbered: boolean;
    items: ClassicBlockInfo[];
  };
};

export type ClassicBlockInfoMeta = BlockHeadingMeta | BlockParagraphMeta | BlockListMeta;

export class ClassicBlockInfo {
  public readonly meta: ClassicBlockInfoMeta;
  public readonly elements: ClassicElementInfo[] = [];

  constructor(
    private sdk: MultiplatformSDKInstance,
    private standardBlock: StandardBlock,
    public readonly blockIndex: number
  ) {
    this.meta = this.getBlockMeta();
    this.elements = standardBlock.elements.map(element => ClassicElementInfo.create(this.sdk, blockIndex, element));
  }

  private getBlockMeta = (): ClassicBlockInfoMeta => {
    const StandardBlock = this.sdk.sdkModule.StandardBlock;
    const block = this.standardBlock;
    if (block instanceof StandardBlock.Heading) {
      return {
        type: ClassicBlockInfoType.Heading,
        data: {
          level: block.level
        }
      };
    }
    if (block instanceof StandardBlock.List) {
      return {
        type: ClassicBlockInfoType.List,
        data: {
          isNumbered: block.isNumbered,
          items: block.items.map(item => new ClassicBlockInfo(this.sdk, item, this.blockIndex))
        }
      };
    }
    if (block instanceof StandardBlock.Paragraph) {
      // TODO(albertusdev): Consider doing 1-1 mapping here and only fallback to paragraph if failed, and add test/warning for this
      return {
        type: ClassicBlockInfoType.Paragraph,
        data: null
      };
    }

    logError(new Error(`Unexpected block type: ${block}`));
    return {
      type: ClassicBlockInfoType.Paragraph,
      data: null
    };
  };
}
