import assert from 'assert';
import { createLocalStorageStore } from 'lib/zustand';
import { logAnalyticsEventOnSpeedChange } from 'modules/analytics/impl/playbackAnalytics';
import { usePlaybackStore } from 'modules/listening/stores/playback/playbackStore';
import { MeasurementKey } from 'modules/profiling/measurementTypes';
import { profilingStoreActions } from 'modules/profiling/profilingStore';
import { subscriptionStoreSelectors } from 'modules/subscription/stores/selectors';
import backwardsCompatibility from 'modules/utils/backwards-compatibility';

import {
  BASE_FACTOR_SPEED_WPM,
  MAXIMUM_FREE_RELATIVE_SPEED,
  MAXIMUM_FREE_WPM,
  MAXIMUM_PREMIUM_RELATIVE_SPEED,
  MAXIMUM_PREMIUM_WPM,
  MINIMUM_RELATIVE_SPEED,
  MINIMUM_WPM
} from '../utils/constants';
import { AnalyticsEventKey, AnalyticsEventProperty } from 'modules/analytics/analyticsTypes';

type SpeedState = {
  currentSpeedInWPM: number;
  isInAutomaticIncreaseSpeedMode: boolean;
};

type SpeedChangeSource = AnalyticsEventProperty<AnalyticsEventKey.playerSpeedChanged>['source'];

let cleanUpPreviousWordsListenedListener: () => void = () => {};

export const useSpeedStore = createLocalStorageStore<SpeedState>(
  () => {
    return {
      currentSpeedInWPM: 200,
      isInAutomaticIncreaseSpeedMode: false
    };
  },
  {
    storageName: 'speechifySpeedPreference',
    version: 1
  }
);

export const initializeSpeedStore = async () => {
  const { isInAutomaticIncreaseSpeedMode } = useSpeedStore.getState();
  if (!isInAutomaticIncreaseSpeedMode) return;

  initializeAutomaticSpeedIncreaseMode();
};

const initializeAutomaticSpeedIncreaseMode = () => {
  cleanUpPreviousWordsListenedListener?.();

  const { currentPlaybackInfo } = usePlaybackStore.getState();

  assert(currentPlaybackInfo, 'currentPlaybackInfo should be defined when initializeSpeedStore is called');

  // every 500 words, increment the speed by 10 words per minute
  cleanUpPreviousWordsListenedListener = currentPlaybackInfo.addWordsListenedListener(event => {
    const { currentSpeedInWPM } = useSpeedStore.getState();

    if (event.count % 500 === 0) {
      setSpeedInWPM(currentSpeedInWPM + 10, 'autoIncrease');
    }
  });
};

const clampSpeed = (speed: number) => {
  const maxSpeed = subscriptionStoreSelectors.getIsPremium() ? MAXIMUM_PREMIUM_RELATIVE_SPEED : MAXIMUM_FREE_RELATIVE_SPEED;
  return Math.max(MINIMUM_RELATIVE_SPEED, Math.min(speed, maxSpeed));
};

const clampSpeedInWPM = (speedInWPM: number) => {
  const maxWpm = subscriptionStoreSelectors.getIsPremium() ? MAXIMUM_PREMIUM_WPM : MAXIMUM_FREE_WPM;
  return Math.max(MINIMUM_WPM, Math.min(speedInWPM, maxWpm));
};

export const fetchInitialSpeedInWPM = async (): Promise<number> => {
  await profilingStoreActions.measureAction(() => useSpeedStore.waitForInitialHydration(), MeasurementKey.zustandIdbHydration, {
    storeName: 'speedStore'
  });

  const speedInWPM = useSpeedStore.getState().currentSpeedInWPM;
  return clampSpeedInWPM(speedInWPM);
};

const onAutomaticIncreaseSpeedPreferenceChange = (isEnabled: boolean) => {
  if (!isEnabled) {
    cleanUpPreviousWordsListenedListener();
    useSpeedStore.setState(s => ({ ...s, isInAutomaticIncreaseSpeedMode: false }));
    return;
  }

  useSpeedStore.setState(s => ({ ...s, isInAutomaticIncreaseSpeedMode: true }));
  initializeAutomaticSpeedIncreaseMode();
};

const setSpeed = (speed: number) => {
  const { currentPlaybackInfo } = usePlaybackStore.getState();
  if (!currentPlaybackInfo) return;

  // min 0.5, max 4.5
  const clampedSpeed = clampSpeed(speed);
  const currentSpeed = Number(clampedSpeed.toFixed(1));
  const currentSpeedInWPM = currentSpeed * BASE_FACTOR_SPEED_WPM;

  useSpeedStore.setState(s => ({ ...s, currentSpeedInWPM }));
  currentPlaybackInfo.controls.setRelativeSpeed(currentSpeed);

  // legacy support
  backwardsCompatibility.setPlaybackSpeed(currentSpeed);

  logAnalyticsEventOnSpeedChange({
    speed: currentSpeed,
    wpm: currentSpeedInWPM,
    source: 'speedUI'
  });
};

const setSpeedInWPM = (speedInWPM: number, source: SpeedChangeSource) => {
  const { currentPlaybackInfo } = usePlaybackStore.getState();
  if (!currentPlaybackInfo) return;

  const clampedSpeedInWPM = clampSpeedInWPM(speedInWPM);

  useSpeedStore.setState(s => ({ ...s, currentSpeedInWPM: clampedSpeedInWPM }));
  currentPlaybackInfo.controls.setSpeed(clampedSpeedInWPM);

  logAnalyticsEventOnSpeedChange({
    speed: clampedSpeedInWPM / BASE_FACTOR_SPEED_WPM,
    wpm: clampedSpeedInWPM,
    source: source
  });
};

const incrementSpeed = (source: SpeedChangeSource) => {
  const currentSpeed = useSpeedStore.getState().currentSpeedInWPM;
  setSpeedInWPM(currentSpeed + 0.1 * BASE_FACTOR_SPEED_WPM, source);
};

const decrementSpeed = (source: SpeedChangeSource) => {
  const currentSpeed = useSpeedStore.getState().currentSpeedInWPM;
  setSpeedInWPM(currentSpeed - 0.1 * BASE_FACTOR_SPEED_WPM, source);
};

const switchToFreeSpeed = () => {
  const currentSpeedInWPM = useSpeedStore.getState().currentSpeedInWPM;
  const clampedSpeedInWPM = clampSpeedInWPM(currentSpeedInWPM);
  setSpeedInWPM(clampedSpeedInWPM, 'switchToFree');
};

export const speedStoreActions = {
  decrementSpeed,
  incrementSpeed,
  initializeSpeedStore,
  onAutomaticIncreaseSpeedPreferenceChange,
  setSpeed,
  switchToFreeSpeed
};

export const speedStoreSelectors = {
  relativeSpeed: (state: SpeedState) => {
    const relativeSpeed = state.currentSpeedInWPM / BASE_FACTOR_SPEED_WPM;
    return Number(relativeSpeed.toFixed(1));
  },
  isInAutomaticIncreaseSpeedMode: (state: SpeedState) => state.isInAutomaticIncreaseSpeedMode
};
