import React, { ReactNode, useEffect } from 'react';

import OcrSplash from 'assets/icons/ocrSplash';
import Button from 'components/elements/Button';
import ModalComponent from 'components/elements/Modal';
import * as speechify from 'lib/speechify';
import { logSegmentEvent } from 'utils/analytics';
import { useSelector } from 'store';

interface OCRUpsellModalProps {
  open?: boolean;
  onClose: () => void;
}

const OCRUpsellModal = ({ open = false, onClose }: OCRUpsellModalProps): ReactNode => {
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    if (open) {
      logSegmentEvent('web_app_ocr_upsell_shown');
    }
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const handleUpgrade = () => {
    window.open(speechify.getUpgradeURL('web_app_ocr_upsell_upgrade'), '_blank');
    logSegmentEvent('web_app_ocr_upsell_upgrade_clicked');
    onClose();
  };

  return (
    user &&
    speechify.canUpgrade(user) && (
      <ModalComponent open={open} showCloseButton onClose={handleClose} classNames="min-w-min" dialogClassNames="z-2000">
        <div className="flex h-full w-fullCard flex-none flex-col justify-center sm:px-14 sm:py-10 md:px-10 md:py-10">
          <div className="flex flex-col items-center">
            <OcrSplash />
            <h3 className="mb-6 mt-4 font-recoleta text-4xl font-bold leading-8 text-glass-700">Upgrade to listen</h3>
            <p className="mb-8 text-center leading-6 text-glass-600">
              Speechify does not fully support this file because it requires additional scan processing. Upgrade to premium to listen to text in image-only
              PDFs, screenshots, images, + more.
            </p>

            <Button title="Upgrade" onClick={handleUpgrade} className="mt-2 font-ABCDiatype text-lg font-medium" style={{ minWidth: 328 }} />
          </div>
        </div>
      </ModalComponent>
    )
  );
};

export default OCRUpsellModal;
