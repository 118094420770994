import { usePlaybackStore } from 'modules/listening/stores/playback/playbackStore';
import { SDKVoiceFacade } from 'modules/sdk/lib';
import type { ListeningDependencies } from 'modules/sdk/listeningDependencies';

import { useVoiceStore } from '../voicesStore';
import { stopPreview } from './stopPreview';

export const initializeVoiceStore = async (deps: ListeningDependencies) => {
  useVoiceStore.setState({
    isLoading: false,
    lastPlayingVoice: deps.playbackInfo.voiceOfCurrentUtterance,
    recommendedVoices: SDKVoiceFacade.singleton.getRecommendedVoiceInfos(),
    personalVoices: SDKVoiceFacade.singleton.getPersonalVoiceInfos(),
    allVoices: SDKVoiceFacade.singleton.getAllVoiceInfos()
  });

  // note: it's safe to not clean this up because the listener will be removed when the PlaybackInfo is destroyed, see PlaybackInfo.destroy() implementation
  const playbackInfo = usePlaybackStore.getState().currentPlaybackInfo;

  playbackInfo?.addStateListener(state => {
    if (state.isPlaying) {
      stopPreview();
    }
  });
};
