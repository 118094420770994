import { create, StoreApi } from 'zustand';

import { StateCreator } from './types';

export type NonPersistentStore<State> = {
  (): State;
  <PartialState>(selector: (state: State) => PartialState): PartialState;
  getState: StoreApi<State>['getState'];
  setState: StoreApi<State>['setState'];
  subscribe: StoreApi<State>['subscribe'];
  reset: () => void;
};

export const LISTENING_SCREEN_STORES: NonPersistentStore<unknown>[] = [];

export function createNonPersistentStore<T>(initialState: StateCreator<T>, isListeningScreenStore = true) {
  const store = create(initialState);

  const useStore = <U>(selector: (state: T) => U) => {
    return store(selector ?? (state => state));
  };

  const reset = () => {
    store.setState(initialState, true);
  };

  Object.assign(useStore, { ...store, reset });

  const finalStore = useStore as NonPersistentStore<T>;

  if (isListeningScreenStore) {
    LISTENING_SCREEN_STORES.push(finalStore);
  }

  return finalStore;
}
