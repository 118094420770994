export enum IntegrationService {
  GOOGLE_DRIVE = 'google-drive',
  DROPBOX = 'dropbox',
  ONE_DRIVE = 'one-drive',
  CANVAS = 'canvas'
}

export interface IntegrationAuthUser {
  email: string;
  name: string;
}

export type IntegrationAuthResponse =
  | {
      authorized: false;
      userMetadata?: never;
    }
  | {
      authorized: true;
      userMetadata: IntegrationAuthUser;
    };

export type IntegrationAuthorizations = Record<IntegrationService, IntegrationAuthResponse>;

export type IntegrationAuthorizeResponse =
  | {
      authorized: false;
      authorizationUrl: string;
      userMetadata?: never;
    }
  | {
      authorized: true;
      authorizationUrl?: never;
      userMetadata: IntegrationAuthUser;
    };

export enum IntegrationFileType {
  FILE = 'file',
  FOLDER = 'folder'
}

export const INTEGRATION_ROOT_FOLDER = 'root' as const;

export interface IntegrationRecord {
  id: string;
  type: IntegrationFileType;
  directory: typeof INTEGRATION_ROOT_FOLDER | string;
  name: string;
  mimeType: string;
  createdTime: string;
  actions: Partial<Record<string, string>>;
  isShared?: boolean;
  service: IntegrationService;
}

export interface IIntegrationFolder {
  id: string;
  name: string;
  directory: string | null;
  isShared?: boolean;
  createdTime: string | null;
  type: IntegrationFileType.FOLDER;
}

export interface IntegrationFolder extends Omit<IntegrationRecord, 'createdTime' | 'directory'>, IIntegrationFolder {
  type: IntegrationFileType.FOLDER;
  sizeBytes: null;
  createdTime: string | null;
}

export interface IntegrationFileActions extends Partial<Record<string, string>> {
  signed: string;
  view: string;
  download: string;
}

export interface IntegrationFile extends IntegrationRecord {
  type: IntegrationFileType.FILE;
  sizeBytes: number;
  fileExtension: string;
  imageUrl: string;
  actions: IntegrationFileActions;
}

export type IntegrationItemRecord = IntegrationFolder | IntegrationFile;

export interface IntegrationFilesResponse {
  files: IntegrationItemRecord[];
  cursor?: string;
}

export enum IntegrationsActionType {
  fetchAuths = 'integration/fetchAuths',
  fetchSuggestions = 'integration/fetchSuggestions',
  authorizeService = 'integration/authorizeService',
  logoutFromService = 'integration/logoutFromService'
}

export enum SORT_BY_FIELD {
  NAME = 'name',
  CREATED_TIME = 'createdTime'
}

export enum SORT_ORDER {
  ASC = 'asc',
  DESC = 'desc'
}

export type SortValues = `${SORT_BY_FIELD}:${SORT_ORDER}`;
