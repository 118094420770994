import { createNonPersistentStore } from 'lib/zustand';
import type { EntitlementsInfo } from 'modules/sdk/lib';

type SubscriptionStateAnonymous = {
  entitlements: undefined;
  isNewUser: undefined;
  isOnTrial: undefined;
  isPremium: undefined;
  isStripe: undefined;
  mobileAppInstalled: undefined;
  firstPdfItemId: undefined;
  isLoading: true;
};

type SubscriptionStateAuthenticated = {
  entitlements: EntitlementsInfo;
  isNewUser: boolean;
  isOnTrial: boolean;
  isPremium: boolean;
  isStripe: boolean;
  firstPdfItemId: string | undefined;
  mobileAppInstalled: boolean;
  isLoading: false;
};

export type SubscriptionStoreState = SubscriptionStateAnonymous | SubscriptionStateAuthenticated;

export const useSubscriptionStore = createNonPersistentStore<SubscriptionStoreState>(() => {
  return {
    entitlements: undefined,
    isLoading: true,
    isNewUser: undefined,
    isOnTrial: undefined,
    isPremium: undefined,
    isStripe: undefined,
    mobileAppInstalled: undefined,
    firstPdfItemId: undefined
  };
});
