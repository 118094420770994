import { useEffect } from 'react';

import { IUser } from 'interfaces/user';
import * as speechify from 'lib/speechify';
import { format } from 'utils/dates';

import { browserNameAndVersion } from './browser';

let bannerVerbCallback: (verb: string) => void;
let msgObserver: MutationObserver;
let msgShowObserver: MutationObserver;
let newMsgIntercepted = false;

type VariantsObject = {
  webPlayerRedesign?: string;
  isDefaultIntercomLauncher?: boolean;
};

let variantsCache: VariantsObject = {
  webPlayerRedesign: 'control'
};

export function bootIntercom(currentUser?: IUser, variants?: VariantsObject) {
  if (!speechify.isPremium(currentUser)) return;

  // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
  const intercom = window['Intercom'];

  if (intercom) {
    if (window.innerWidth > 900) {
      if (currentUser) {
        const { displayName, email, uid } = currentUser;

        if (variants) {
          variantsCache = variants;
        }

        const config = {
          app_id: process.env.NEXT_PUBLIC_INTERCOM_APPID,
          custom_launcher_selector: '#chat_launcher',
          email: email || undefined,
          name: displayName || undefined,
          user_id: uid,
          vertical_padding: 20,
          isNewListeningExperience: true
        };

        // cache config for possible future use
        // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
        window['intercomConfig'] = config;
      }

      // hidden?
      const hideControls = localStorage.getItem('hidecontrols') === 'true';

      // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
      if (window['intercomConfig']) {
        // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
        intercom('boot', window['intercomConfig']);
        // With the new app redesign we disable the default launcher
        if (variantsCache.webPlayerRedesign === 'redesign') {
          intercom('update', { hide_default_launcher: true });
        } else {
          intercom('update', { hide_default_launcher: false });
        }
        // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
        window['intercomBooted'] = true;
      }

      if (hideControls) {
        intercom('update', { hide_default_launcher: true });
      }

      const onMsgMutate = (doc: $TSFixMe) => {
        if (newMsgIntercepted) return;

        const newMsgBtn = doc?.querySelector('.new-conversation-button');
        if (!newMsgBtn) return;

        newMsgBtn.addEventListener('click', (e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();

          // @ts-expect-error TS(2345): Argument of type 'IUser | undefined' is not assign... Remove this comment to see the full error message
          showNewMessage(currentUser, 'I need help with: ');
        });

        newMsgIntercepted = true;
      };

      const observeMessengerFrame = () => {
        // ESLint: Unexpected any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const msgFrame = document.getElementsByName('intercom-messenger-frame')[0] as any;
        if (!msgFrame) return;

        msgObserver = new MutationObserver(() => {
          onMsgMutate(msgFrame.contentDocument);
        });

        msgObserver.observe(msgFrame.contentDocument, { subtree: true, childList: true });
      };

      intercom('onShow', () => {
        msgShowObserver = new MutationObserver(() => {
          observeMessengerFrame();
        });

        msgShowObserver.observe(document, { subtree: true, childList: true });
      });

      intercom('onHide', () => {
        msgObserver?.disconnect();
        msgShowObserver?.disconnect();
        newMsgIntercepted = false;
      });
    }
  }
}

export function setBannerVerbCallback(callback: (verb: string) => void) {
  bannerVerbCallback = callback;
}

function checkForBanner() {
  // ESLint: 'pageObserver' is never reassigned
  // eslint-disable-next-line prefer-const
  let bannerObserver: MutationObserver;

  const onBannerMutate = (doc: $TSFixMe) => {
    const bannerText = doc.querySelector('.intercom-block-paragraph')?.innerText;

    if (bannerText) {
      const bannerVerb = bannerText.match(/\[(.*?)\]/)?.[1];

      if (bannerVerb) {
        bannerVerbCallback && bannerVerbCallback(bannerVerb);

        const title = bannerText.replace(`[${bannerVerb}]`, '').trim();
        doc.querySelector('.intercom-block-paragraph').innerText = title;

        if (!title) {
          const frame = document.getElementsByName('intercom-banner-frame')[0];

          if (frame) {
            frame.style.display = 'none';
            frame.style.height = '0px';

            setTimeout(() => {
              document.body.style.marginTop = '0px';
            }, 250);
          }
        }

        bannerObserver?.disconnect();
        pageObserver?.disconnect();
      }
    }
  };

  const observeBannerFrame = () => {
    // ESLint: Unexpected any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bannerFrame = document.getElementsByName('intercom-banner-frame')[0] as any;
    if (!bannerFrame) return;

    bannerObserver = new MutationObserver(() => {
      onBannerMutate(bannerFrame.contentDocument);
    });

    bannerObserver.observe(bannerFrame.contentDocument, { subtree: true, childList: true });
  };

  const pageObserver = new MutationObserver(() => {
    observeBannerFrame();
  });

  pageObserver.observe(document, { subtree: true, childList: true });
}

if (typeof window !== 'undefined') {
  checkForBanner();
}

export function buildCurrentUserMessage(currentUser?: IUser) {
  if (!currentUser) {
    return '';
  }

  const planDisplayName = () => {
    if (speechify.isCanceled(currentUser)) return 'Canceled';
    if (speechify.isOnTrial(currentUser)) return 'Trial';
    if (speechify.isPremium(currentUser)) return 'Premium';

    return 'No current plan';
  };

  const sourceDisplayName = () => {
    if (speechify.isApple(currentUser)) return ' (Apple)';
    if (speechify.isPayPal(currentUser)) return ' (PayPal)';
    if (speechify.isPlayStore(currentUser)) return ' (Google Play)';
    if (speechify.isStripe(currentUser)) return ' (Stripe)';

    return '';
  };

  const { email, subscription } = currentUser;

  const intro = `Hi Speechify.\n\n`;
  let details = '';

  const subscriptionExpires = subscription?.renewsAt && format(subscription.renewsAt, 'D MMM YYYY');
  const isLifetime = speechify.isLifetime(currentUser);

  details += `- Email: ${email}\n`;
  details += `- Subscription: ${planDisplayName()}${sourceDisplayName()}\n`;
  details += isLifetime ? '- Lifetime Subscription\n' : subscriptionExpires ? `- Expires on: ${subscriptionExpires}\n` : '';
  details += `- Browser: ${browserNameAndVersion()}\n\n`;

  return intro + details;
}

export function hideIntercom() {
  // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
  const intercom = window['Intercom'];

  if (intercom) {
    intercom('update', { hide_default_launcher: true });
  }
}

export function showIntercom() {
  // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
  const hasBooted = window['intercomBooted'];
  // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
  const intercom = window['Intercom'];

  const hideControls = localStorage.getItem('hidecontrols') === 'true';

  if (intercom) {
    if (!hasBooted) {
      bootIntercom();
    } else {
      if (!hideControls) {
        intercom('update', { hide_default_launcher: false });
      }
    }
  }
}

// ESLint: 'className' is defined but never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function showCustomIntercom(className: string) {
  // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
  const hasBooted = window['intercomBooted'];
  // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
  const intercom = window['Intercom'];

  const isTablet = window.matchMedia('(max-width: 768px)').matches;

  if (intercom) {
    if (!hasBooted) {
      bootIntercom();
    } else {
      intercom('update', {
        hide_default_launcher: true,
        alignment: 'left',
        horizontal_padding: isTablet ? 80 : 0,
        vertical_padding: 0,
        custom_launcher_selector: '.support-menu-help-center'
      });
    }
  }
}

export function showNewMessage(currentUser: IUser, messageSuffix: string) {
  // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
  if (window['Intercom']) {
    // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    window['Intercom']('showNewMessage', buildCurrentUserMessage(currentUser) + messageSuffix);
  }
}

export function bootIntercomDefault(currentUser: IUser) {
  const isSubscriptionCanceled = speechify.isCanceled(currentUser);

  if (speechify.isPremium(currentUser) || isSubscriptionCanceled) {
    // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    const intercom = window['Intercom'];

    if (!intercom) return;

    const { displayName, email, uid } = currentUser;

    const config = {
      app_id: process.env.NEXT_PUBLIC_INTERCOM_APPID,
      custom_launcher_selector: '#chat_launcher',
      email: email || undefined,
      name: displayName || undefined,
      user_id: uid,
      vertical_padding: 0,
      isNewListeningExperience: true,
      hide_default_launcher: false
    };

    // cache config for possible future use
    // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    window['intercomConfig'] = config;

    // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    if (window['intercomConfig']) {
      // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
      intercom('boot', window['intercomConfig']);
    }

    if (isSubscriptionCanceled && !localStorage.getItem('intercomSetCancelationMessage')) {
      intercom('showNewMessage', 'I have a question regarding billing/subscription');
    }

    intercom('onHide', () => {
      if (isSubscriptionCanceled && !localStorage.getItem('intercomSetCancelationMessage')) {
        localStorage.setItem('intercomSetCancelationMessage', 'true');
      }
    });
  }
}

export const shutDownIntercom = () => {
  // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
  if (window['Intercom']) {
    // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    window['Intercom']('shutdown');
  }
};

export const useIntercomDefault = (currentUser: IUser | null) => {
  useEffect(() => {
    if (currentUser) {
      bootIntercomDefault(currentUser);
    } else {
      shutDownIntercom();
    }
  }, [currentUser]);
};
